import React, { useEffect } from 'react'

import { RouteComponentProps } from '@reach/router'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import sortBy from 'lodash/sortBy'

import SEO from 'components/shared/seo'
import { useProjectArticleContent } from 'content-queries/blog/project'
import fireEvent from 'context/tracking/events'
import ArticleSnippet from 'mx/blog/article-snippet'
import FilterLayout from 'mx/blog/filter-layout'
import Hero from 'mx/blog/hero'
import Layout from 'mx/layout'

export interface ArticleItem {
  author: {
    avatar: any
    firstName: string
    lastName: string
  }
  category: string
  image: {
    title?: string
    description: string
    localFile: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData
      }
    }
  }
  introduction: string
  title: string
  publishedDate: string
  publishingUrl?: string
}

const BlogPage = (_: RouteComponentProps) => {
  useEffect(() => {
    document.body.scrollTo({ top: 0 })
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'Blog',
    })
  }, [])

  const { projectArticles } = useProjectArticleContent()

  const projectSetup = projectArticles.map((n: any) => ({
    author: n.node.author,
    category: n.node.category,
    image: n.node.image,
    introduction: n.node.introduction.introduction,
    publishedDate: n.node.date,
    publishingUrl: n.node.publishingUrl,
    title: n.node.title,
  }))

  const articlesCollection: ArticleItem[] = sortBy(
    [...projectSetup],
    (i) => i.publishedDate,
  ).reverse()
  return (
    <Layout>
      <Hero {...articlesCollection[0]} />
      <FilterLayout
        items={(articlesCollection as ArticleItem[])
          .splice(1, articlesCollection.length - 1)
          .map((i) => ({
            ...i,
          }))}
      >
        {({ filteredItems }) => (
          <>
            <ArticleSnippet items={filteredItems} />
          </>
        )}
      </FilterLayout>
    </Layout>
  )
}

export default BlogPage

export const Head = () => (
  <SEO
    title="Blog"
    description="Read FORM Kitchens' blog for design inspiration, project highlights, and the latest on our premium materials and functionality."
    path="/blog"
  />
)
